import axios from 'axios'
import jwt from 'jsonwebtoken'

// ** Request Interceptor
axios.interceptors.request.use(
    (config) => {
        // ** Get token from localStorage
        const token = localStorage.getItem('accessToken')
        if (token) {
            // ** Verify token and get payload
            const payload = jwt.verify(token, process.env.REACT_APP_JWT_SECRET)
            // ** If token is present add it to request's Authorization Header
            config.headers.Authorization = `Bearer ${payload.accessToken}`
        }
        return config
    },
    (error) => Promise.reject(error)
)
